import React from 'react';
import styled from 'styled-components';
import Theme from '../Theme'

const Help = styled.span`
  height:14px;
  width:14px;
  border-radius: 100%;
  background: ${Theme.colors.info.bg}; 
  border: 1px solid ${Theme.colors.info.border};
  color:${Theme.colors.info.text};
  font-family:Circe-Rounded;
  display: inline-block;
  font-size: 10px;
  text-align:center;
  font-weight:bold;
  position:relative;
  top:-2px;
  left:5px;
  
   >div {
      display:none;
      background:white;
      padding:7px;
      position:absolute;
      top:-40px;
      left:-4px;
      white-space: nowrap;
      font-weight:300;
      color:#707070;  
      box-shadow:0 0 6px #ccc;
    
      &::before {
        content: " ";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;
        position: absolute;
        bottom: -5px;
      }
  }
  
  &:hover {
    div {
      display:inline-block;
    }  
  }
`;


const App = ({text}) => {
  return(
    <Help>
      <span>?</span>
      <div>{text}</div>
    </Help>
  );
}

export default App;



export const phone = value => {

  return value
    .replace(/\D/g,'')
    .replace(/(\d{3})(\d)/,'$1-$2')
    .replace(/(\d{3})(\d)/,'$1-$2')
    .replace(/(\d{4})\d+?$/,'$1');
}

export const time = value => {
    value = value.trim();

    if ( value[0] === '1' )
        value = value.replace( /^(1[012])(\d{1,2})/, '$1:$2' );
    else
        value = value.replace( /^([1-9])(\d{1,2})/, '$1:$2' );

    value = value.replace( /^(\d{1,2}):?([0-5][0-9]) ?(am|pm)$/, '$1:$2 $3' );

    return value;
};

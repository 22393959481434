import React from 'react';
import styled from 'styled-components'
import Theme from '../Theme'

const handleSize = sizeType => {
    switch(sizeType){
        case 'extraSmall':
            return '80px';
        case 'small':
            return '140px';
        case 'big':
            return '460px';
        default:
            return '300px';
    }
}

const Frag = styled.div`
    display:inline-block;
    vertical-align: top;
`;

const Label = styled.label`
  font-size:${Theme.font.medium.size};
  font-weight:normal;
  font-family:Circe-Rounded;
  display:block;
  padding-bottom:2px;
  color:#707070;

  >.requiredField {
    color:${Theme.colors.error.text};
  }

`;

const borderDefault = `
    border-width:1px;
    border-style:solid;
`;
const InputBase = styled.input`
    background:${Theme.colors.neutrals.white};
    border-color:${Theme.colors.neutrals.silver};
    border-width:1px;
    border-style:solid;
    border-radius:${Theme.form.input.radius};
    font-size:${Theme.font.small.size};
    line-height:${Theme.font.small.lineHeight};
    height:40px;
    padding:${Theme.form.input.padding};
    color:${Theme.colors.neutrals.gray};
    width:${({size}) => handleSize(size) };

   &:disabled {
        background: ${Theme.colors.extra.whiteSmoke};
        ${borderDefault};
        border-color: ${Theme.colors.neutrals.silver};
        color: ${Theme.colors.neutrals.medium};
   }

   &:disabled::placeholder {
        color:#eee;
   }

   &:hover {
      ${borderDefault} ;
   }

   &:active {
      ${borderDefault};
   }

   &:focus {
    ${borderDefault}
    box-shadow:0px 0px 6px ${Theme.colors.action}
    border-color:${Theme.colors.secondary}
   }

   &::placeholder {
      color:${Theme.colors.neutrals.silver};
      font-size:${Theme.font.small};
      font-family:initial;
   }

`;

const SpanHelp = styled.span`
  font-size:${Theme.font.extraSmall.size};
  font-weight:normal;
  font-family:Circe-Rounded;
  display:block;
  color:#516F90;
  margin-top:4px;
`;

const SpanError = styled(SpanHelp)`
  color:${Theme.colors.error.text}
`;

const RequiredText = () => <span className='requiredField'>*</span>;

const SelectUi = styled.div`
  width:${({size}) => handleSize(size)};
  height:40px;
  background:white;
  border-radius:2px;
  border:1px solid ${Theme.colors.neutrals.silver};
  cursor:pointer;
  position:relative;
  box-sizing:border-box;
  padding:12px 8px;
  display:flex;
  align-items:center;
  justify-content:space-between;

  &.open {
      border-color:${Theme.colors.secondary};
      box-shadow:0 0 6px ${Theme.colors.action};
  }

  &.error {
    border-color:${Theme.colors.error.border};
  }

  &.error.open {
    border-color:${Theme.colors.error.border};
    box-shadow:0 0 6px ${Theme.colors.error.shadow};
  }

  &.valid {
    border-color:${Theme.colors.success.border};
  }

   &.valid.open {
    border-color:${Theme.colors.success.border};
    box-shadow:0 0 6px ${Theme.colors.success.shadow};
  }
`;

const Value = styled.span`
  color:${props => props.option ? '#707070' : Theme.colors.neutrals.gray};
  font-size:14px;
  white-space: nowrap;
  overflow:hidden;
  ${props => props.option && `
    display:inline-block;
    flex:1;
  `}

  >span {
    color:#707070;
  }
`;

const Options = styled.ul`
  padding:0;
  margin:4px 0 0 0;
  background:white;
  border:1px solid ${Theme.colors.neutrals.silver};
  box-shadow:0 0 6px ${Theme.colors.neutrals.silver};
  border-radius:2px;
  list-style:none;
  width:auto;
  display:inline-block;
  position: absolute;
  top: 107%;
  left: -1px;
  z-index:10;

  ${props => props.isChild && `
    position:absolute;
    left:100%;
    margin-left:6px;
    top:-4px;

    &::before {
        content: " ";
        height: 100%;
        width: 6px;
        background: transparent;
        display: block;
        position: absolute;
        left: -7px;
    }
  `}


  li {
    font-size:${Theme.font.small.size};
    color:#707070;
    height:32px;
    cursor:pointer;
    padding:0px 4px 0 8px;
    position:relative;
    display:flex;
    justify-content:space-between;
    align-items:center;

    >ul {
      display:none;
    }


    &:hover,
    &.selected {
      background:${Theme.colors.info.bg};

      >ul {
        display:inline-block;
      }
    }

    &.alone {
      border-top:1px solid ${Theme.colors.neutrals.silver};
    }

  }
`;

const OptionGroup = styled.div`
    color:#707070;
    font-size:12px;
    padding:8px;
    font-weight:bold;
    cursor:default;
    white-space: nowrap;
`;

export {
    Frag,
    Label, SpanError, SpanHelp, RequiredText,
    InputBase, SelectUi, Value, Options, OptionGroup
};

import React, {forwardRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import {Label, SpanHelp} from '../Common/input'
import Help from '../Common/tooltip'
import Theme from '../Theme'

const Frag = styled.div`
    display:inline-block;
    vertical-align: top;
`;

const CheckBoxWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    &:focus {
        outline: none;
    }
`;

const CheckBoxLabel = styled.label`
    width: 36px;
    height: 20px;
    border-radius: 100px;
    background: ${Theme.colors.neutrals.white};
    cursor: pointer;
    border: 1px solid ${Theme.colors.neutrals.silver};
    box-sizing: border-box;

    &:focus {
        box-shadow: 0px 0px 6px ${Theme.colors.action};
        border: 1px solid ${Theme.colors.secondary};
        outline: none;
    }

    &::after {
        content: "";
        display: block;
        border-radius: 100%;
        width: 12px;
        height: 12px;
        margin: 3px;
        background: #ccc;
        transition: 0.2s;
    }
`;

const CheckBox = styled.input`
    width: 0;
    height: 0;
    margin: 0;
    visibility: hidden;
    pointer-events: none;

    &:checked + ${CheckBoxLabel} {
        background: ${Theme.colors.info.bg};
        border: 1px solid ${Theme.colors.info.border};

        &::after {
            background:${Theme.colors.info.text};
            margin-left: 20px;
        }
    }
`;

const ToggleLabel = styled.label`
    display: inline-block;
    color: ${Theme.colors.neutrals.medium};
    font-size: ${Theme.font.small.size};
    vertical-align: top;
    margin-left: 8px;
    outline: none;
`;

const Toggle = forwardRef(({
    name,
    label,
    tooltip,
    help,
    offText,
    onText,
    singleText,
    on=false,
    onChange,
    ...props
},ref) => {
    const [value,setValue] = useState(on);

    let textOn, textOff;

    if (onText) textOn = onText;
    if (offText) textOff = offText;

    if (singleText) {
        textOff = singleText;
        textOn = singleText;
    }

    useEffect(()=>{
        setValue(on);
    },[on])

    const range = Math.floor(Math.random() * 2000);

    const handleChange = e => {
        setValue(e.target.checked);
        if (onChange) onChange(e);
    }

    const id = `${name}_${range}`;

    return (
        <Frag>
            {label && <Label htmlFor={id}>{label} {tooltip && <Help text={tooltip}/>}</Label>}
            <CheckBoxWrapper>
                <CheckBox
                    name={name}
                    id={id}
                    type="checkbox"
                    textOn={textOn}
                    ref={ref}
                    checked={value && true}
                    onChange={handleChange}
                />
                <CheckBoxLabel
                    tabIndex={1}
                    htmlFor={id}
                    // big={big}
                />
                <ToggleLabel htmlFor={id} tabIndex={-1}>
                    {singleText
                        ? singleText
                        : value
                            ? textOn
                            : textOff
                    }
                </ToggleLabel>
            </CheckBoxWrapper>
            {help && <SpanHelp>{help}</SpanHelp>}
        </Frag>
    );
})

export default Toggle;

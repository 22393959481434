import React from 'react';
import styled from 'styled-components';

const solveSpace = (type=0) => {
  if(!Number.isInteger(type)){
    return '0';
  }
  if(type < 1){
    return '0';
  }
  return type*4;
}

const BaseSpace = styled.div`
  padding-top:${props => solveSpace(props.top)}px;
  padding-left:${props => solveSpace(props.left)}px;
  padding-right:${props => solveSpace(props.right)}px;
  padding-bottom:${props => solveSpace(props.bottom)}px;
  display:${props => props.inline ? 'inline-block' : 'block'}
`;

const Spacer = ({all,...props}) => {

  if(all){
    props.top = all;
    props.left = all;
    props.right = all;
    props.bottom = all;
  }
  return (<BaseSpace {...props} />)
}

export default Spacer;

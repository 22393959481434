import React, {useState, useEffect, useRef, Fragment} from 'react'
import styled from 'styled-components';
import Button, {Base, Primary, Secondary, Delete, Cancel, Small, Big} from './index.js';
import Icon from '../Icons';
import Spacer from '../Spacer';
import {BoxOptions} from '../Input/select'

const Container = styled(Base).attrs(props => ({
    as: 'div',
}))`
    display: inline-flex;
    align-items: stretch;
    padding: 0;

    ${props => props.type === 'primary' && Primary}
    ${props => props.type === 'secondary' && Secondary}
    ${props => props.type === 'cancel' && Cancel}
    ${props => props.type === 'delete' && Delete}
    ${props => props.size === 'small' && Small}
    ${props => props.size === 'big' && Big}

    ${props => props.divider && `
        ${ArrowContainer} {
            border-left-width: 1px;
            border-left-style: dashed;
        }
    `}
`;

const ArrowContainer = styled.div`
    display:inline-flex;
    align-items:center;
    justify-contents:center;
    padding:0 8px;
`;

const Dropdown = ({children, options, text, type,size, divider = true, openTo,visibleOptionsQty}) => {
    const [open,setOpen] = useState(false);
    const popupRef = useRef();

    const handleContainerClick = e => {
        if ( !children )
            setOpen(true);
    };

    const handleArrowClick = e => {
        if ( children )
            setOpen(true);
    };

    const handleClickOutside = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            setOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside,false);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside,false);
        };
    }, []);

    type = children
        ? children.props.type
        : type;

    return (
        <div ref={popupRef} style={{position: 'relative', display: 'inline-flex'}}>
            <Container type={type} size={size} divider={divider} onClick={handleContainerClick}>
                {children || <Spacer left={4}>{text}</Spacer>}
                <ArrowContainer onClick={handleArrowClick}>
                    {open ? <Icon.ArrowUp size='16px'/> : <Icon.ArrowDown size='16px'/>}
                </ArrowContainer>
            </Container>
            <div className={'options-container'}>
                {open && options &&
                    <BoxOptions
                        openTo={openTo}
                        visibleOptionsQty={visibleOptionsQty}
                        options={options.map(opt => ({
                            name: opt.text,
                            value: opt.text,
                            fn: opt.fn
                        }))}
                        setOpen={setOpen}
                        setSelected={()=>{}}
                        onSelect={opt => opt.fn()}
                    />
                }
            </div>
        </div>
    )
}

export default Dropdown;

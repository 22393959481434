import React, {forwardRef, Fragment, useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import Icon from '../Icons/index'
import Theme from '../Theme'
import Ex from '../assets/Ex.svg'
import Check from '../assets/Check.svg'
import Help from '../Common/tooltip'
import {Label, RequiredText} from '../Common/input'
import mergeRefs from '../Common/MergeRefs'
import {Text} from '../Texts/index'

const Frag = styled.div`
    display:inline-block;
    vertical-align: top;
`;

const handleSize = sizeType => {
    switch(sizeType){
        case 'extraSmall':
            return '80px';
        case 'small':
            return '140px';
        case 'big':
            return '460px';
        default:
            return '300px';
    }
}

const SelectUi = styled.div`
  width:${({size}) => handleSize(size)};
  height:40px;
  background:white;
  border-radius:2px;
  border:1px solid ${Theme.colors.neutrals.silver};
  cursor:pointer;
  position:relative;
  box-sizing:border-box;
  padding:12px 8px;
  display:flex;
  align-items:center;
  justify-content:space-between;

  &.open {
      border-color:${Theme.colors.secondary};
      box-shadow:0 0 6px ${Theme.colors.action};
  }

  &.error {
    border-color:${Theme.colors.error.border};
  }

  &.error.open {
    border-color:${Theme.colors.error.border};
    box-shadow:0 0 6px ${Theme.colors.error.shadow};
  }

  &.valid {
    border-color:${Theme.colors.success.border};
  }

   &.valid.open {
    border-color:${Theme.colors.success.border};
    box-shadow:0 0 6px ${Theme.colors.success.shadow};
  }

    &[disabled] {
        cursor: not-allowed;
        background: ${Theme.colors.extra.whiteSmoke};
        border-color: ${Theme.colors.neutrals.silver};
        color: ${Theme.colors.neutrals.medium};
    }

    &[disabled] input::placeholder {
        color:#eee;
    }
`;

const Value = styled(Text).attrs(props => ({
    as: 'span'
}))`
  white-space: nowrap;
  overflow:hidden;
  color: ${Theme.colors.neutrals.medium};

  ${props => props.option && `
    display:inline-block;
    flex:1;
  `}

  >span {
    color:#707070;
  }
`;

const maxOptions = 3;
const optionsSize = 32;

const Options = styled.ul`
  padding:0;
  margin:4px 0;
  background:white;
  border:1px solid ${Theme.colors.neutrals.silver};
  box-shadow:0 0 6px ${Theme.colors.neutrals.silver};
  border-radius:2px;
  list-style:none;
  width:auto;
  display:inline-block;
  position: absolute;
  top: 100%;
  ${props => props.openTo === 'top' && `
    top:unset;
    bottom: 100%;
  `}
  left: -1px;
  z-index:10;
  ${props => !props.hasChildren && `
    max-height: ${props.visibleOptionsQty ? props.visibleOptionsQty * optionsSize : maxOptions * optionsSize}px;
    overflow-x:hidden;
    overflow-y:scroll;
  `}


  ${props => props.isChild && `
    position:absolute;
    left:100%;
    margin-left:4px;
    top:-4px;
  `}


  li {
    font-size:${Theme.font.small.size};
    color:#707070;
    height:32px;
    cursor:pointer;
    padding:0px 4px 0 8px;
    position:relative;
    display:flex;
    justify-content:space-between;
    align-items:center;

    >ul {
      display:none;
    }

    &::before {
        content: " ";
        height: 100%;
        width: 6px;
        background: transparent;
        display: block;
        position: absolute;
        right: -7px;
    }


    &:hover {
      background:${Theme.colors.info.bg};

      >ul {
        display:inline-block;
      }
    }

    &.alone {
      border-top:1px solid ${Theme.colors.neutrals.silver};
    }

  }
`;

const optionsSizeCustom = 42;
const OptionsPeople = styled(Options)`
  max-height: ${props => props.visibleOptionsQty ? props.visibleOptionsQty * optionsSize : optionsSizeCustom * optionsSize}px;
  overflow-y:scroll;

  >li {
    padding-right: 8px;
    height: auto;
    min-height: 32px;
    justify-content: flex-start;
  }
`;


const Span = styled.span`
  font-size:${Theme.font.extraSmall.size};
  font-weight:normal;
  font-family:Circe-Rounded;
  display:block;
  color:#516F90;
  margin-top:4px;
`;

const SpanError = styled(Span)`
  color:${Theme.colors.error.text}
`;

const SelectHidden = styled.select`
    position: absolute;
    height: 1px;
    width: 1px;
    z-index: -1;
    opacity: 0;
`;

const PhotoCover = styled.div`
  height:22px;
  width:22px;
  overflow:hidden;
  border-radius:100%;
  border:1px solid #516F90;
  background:#EAF0F6;
  margin-right:5px;
  display:flex;
  justify-content:center;
  align-items:center;

  >svg {
    margin-top:2px;
  }

  >img {
    width:25px;
    height:25px;
    object-fit:cover;
    object-position:center;
  }
`;

const CustomCheckBox = styled.div`
   background: ${Theme.colors.neutrals.white};
  border-radius: ${Theme.defaultRadius};
  border:1px solid ${Theme.colors.neutrals.silver};
  height:16px;
  width:16px;
  border-radius:2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right:9px;
  &:focus{
      border-color:${Theme.colors.secondary};
      box-shadow:0 0 6px ${Theme.colors.action};
  }

`;

const OptionGroup = styled.div`
    color:#707070;
    font-size:12px;
    padding:8px;
    font-weight:bold;
    cursor:default;
    white-space: nowrap;
`;

const ColorOption = styled.span `
  height:24px;
  width:24px;
  box-sizing: border-box;
  margin-right:5px;
  border-radius:100%;
  border:2px solid white;
  background:${props => props.color ? props.color : 'black' }
  z-index: ${props => props.index > 0 ? 10-props.index : 10};
  margin-left:${props => props.index > 0 ? '-19px' : '0' }
  margin-top: 8px;
  margin-bottom: 8px;
  display: inline-block;
`;


const getSelected = (options,defaultValue) =>{

    let selected;
    options.some((item)=>(
        selected = (item.selected) || (item.value == defaultValue) ? item : getSelected(item.options ? item.options : [],defaultValue))
    )
    return selected;
}

const Dropdown = forwardRef(({
    name,
    label,
    help,
    error,
    valid,
    options,
    placeholder,
    onChange,
    defaultValue,
    visibleOptionsQty,
    openTo,
    startOpen = false,
    ...props
},ref) => {

    let defaultSelected = {value:'',desc:''};

    const [open, setOpen] = useState(startOpen);
    const [selectedOpt, setSelected] = useState(defaultSelected);

    const nodeSelect = useRef();
    const valueRef   = useRef();

    const handleClick = e => {
        if (nodeSelect.current.contains(e.target)) {
            return;
        }
        setOpen(false);
    };

    const reset = () =>{
        setSelected(defaultSelected);
    }

    if(props.resetCallback){
        props.resetCallback(reset);
    }

    const setDefaultValues = useCallback(() => {
        const hasDefault = getSelected(options,defaultValue);

        if(hasDefault && hasDefault.value){
            defaultSelected = {value:hasDefault.value.toString(),desc:hasDefault.name};
            setSelected(defaultSelected);
        } else {
            if ( !valueRef.current.value )
                return;

            let selected = getSelected(options,valueRef.current.value);
            if ( selected && selected.value && selected.value !== selectedOpt.value)
                setSelected({ value: selected.value.toString(), desc: selected.name });
        }
    },)

    useEffect(()=>{
        //if(defaultValue){
        if(options) setDefaultValues();
        //}

    },[options,defaultValue])

    useEffect(()=>{
        valueRef.current.value = selectedOpt.value;
        valueRef.current.dispatchEvent(new Event('blur'));
    }, [selectedOpt]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    let useIcon = false;

    if(props.Required) useIcon = <RequiredText />

    if(valid){
        useIcon = (<img src={Check} />);
    }

    return(
        <Frag>
            {label && !useIcon && <Label htmlFor={name}>{label}</Label>}
            {label && useIcon && <Label htmlFor={name}>{label} {useIcon}</Label>}
            <SelectUi
                onClick={()=>{
                    if (!props.disabled)
                        setOpen(!open)
                }}
                ref={nodeSelect}
                className={[open && 'open', error && 'error', valid && 'valid']}
                {...props}
                title={selectedOpt ? selectedOpt.desc : ''}
            >
                <Value>{(selectedOpt.value.length > 0 ? selectedOpt.desc : <span>{placeholder}</span> )}</Value>
                {open ? <Icon.ArrowUp size='15px' color={Theme.colors.info.border} /> : <Icon.ArrowDown size='15px' color={Theme.colors.info.border} />}
                {open &&
                <BoxOptions
                    options={options}
                    setSelected={setSelected}
                    onChange={onChange}
                    reset={reset}
                    noOptions={props.noOptions}
                    selectedOpt={selectedOpt.value}
                    setOpen={setOpen}
                    onSelect={props.onSelect}
                    visibleOptionsQty={visibleOptionsQty}
                    openTo={openTo}
                />
                }
            </SelectUi>
            {help && <Span>{help}</Span>}
            {error && <SpanError><img src={Ex} style={{marginRight:'6px'}} /> {error}</SpanError>}
            <input name={name} type='hidden' ref={mergeRefs([valueRef, ref])} disabled={props.disabled}/>
        </Frag>
    );
})

// eslint-disable-next-line react/prop-types
export const BoxOptions = ({options,isChild,setSelected,onChange,noOptions,selectedOpt,setOpen,onSelect,visibleOptionsQty,openTo}) => {
    if(!options) return (<Options><li style={{paddingRight:'8px'}}>{noOptions ? noOptions : 'No data found'}</li></Options>)

    const optionsRef  = useRef();

    const handleClick = (item,e) => {
        e.stopPropagation();
        setOpen(false);
        if(onSelect) onSelect(item);
        if(selectedOpt === item.value) return false;
        setSelected({value:item.value.toString(),desc:item.name});
        if(onChange)onChange(item)
    }

    const hasChildren = options.some(el => el.options);

    useEffect(() => {
        if(optionsRef.current){
            const optionsBounds = optionsRef.current.getBoundingClientRect();
            if(optionsBounds.right > window.innerWidth){
                optionsRef.current.style.left = "unset";
                optionsRef.current.style.right = "0px";
            }
        }
    },[])

    return (
        <Options isChild={isChild} visibleOptionsQty={visibleOptionsQty} hasChildren={hasChildren} openTo={openTo} ref={optionsRef}>
            {options.map((item,key)=>(
                <Fragment key={key}>
                    {item.value !== undefined && item.value !== null
                        ?
                        <li value={item.value} key={key} className={item.divider ? 'alone' : ''} onClick={(e)=>{ handleClick(item,e); }}>
                            <Value
                                option
                                style={{marginRight:4}}
                            >
                                {item.name}
                            </Value>
                            {item.options && <Icon.ArrowRight size='15px' color='#7C98B6'/>}
                            {item.options && <BoxOptions options={item.options} isChild={true} setSelected={setSelected} onChange={onChange} selectedOpt={selectedOpt} setOpen={setOpen} visibleOptionsQty={visibleOptionsQty} />}
                        </li>
                        :
                        <OptionGroup>{item.name}</OptionGroup>
                    }
                </Fragment>
            ))}
        </Options>
    );
}



const CustomDropdown =  forwardRef(({name,label,help,error,valid,options,placeholder,defaultValue,multiple,type,tooltip,onChange,visibleOptionsQty,openTo,...props},ref) => {

    const [open, setOpen] = useState(false);
    const [selectedOpt, setSelected] = useState([]);

    const nodeSelect = useRef();
    const valueRef = useRef();

    const handlOpenSelect = e => {
        if (props.disabled)
            return;

        if(multiple)
            if(e.target.classList.contains('1010list-option'))
                return;

        setOpen(!open);
    }

    const reset = () => {
        setSelected([]);
    }

    if(props.resetCallback){
        props.resetCallback(reset);
    }

    const handleClick = e => {
        if (nodeSelect.current.contains(e.target))
            return;

        setOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const setDefaultValues = useCallback(() => {
        let defaults;

        if ( valueRef.current && valueRef.current.value ) {
            if ( multiple ) {
                defaultValue = Array.from( valueRef.current.children )
                    .filter( opt => opt.selected == true )
                    .map( opt => opt.value );
            } else
                defaultValue = defaultValue || valueRef.current.value;
        }

        if(options){
            defaults = options.map((item,key)=>{
                if(item.selected) return key;
            }).filter(e => e != null);
        }

        if(defaultValue){
            defaults = options.map((item,key)=>{
                if(multiple) {
                    if(defaultValue.indexOf(item.value) >= 0) return key;
                }else{
                    if(item.value == defaultValue) return key;
                }
            }).filter(e => e != null);
        }


        if(defaults.length){
            if(multiple){
                if (selectedOpt.length !== defaults.length
                    || !selectedOpt.every((value, index) => value === defaults[index]))
                    setSelected(defaults);
            }else{
                if (selectedOpt[0] !== defaults[0])
                    setSelected([defaults[0]]);
            }
        }

        // const hasDefault = getSelected(options,defaultValue);
        //
        // if(hasDefault && hasDefault.value){
        //     defaultSelected = {value:hasDefault.value.toString(),desc:hasDefault.name};
        //     setSelected(defaultSelected);
        // } else {
        //     if ( !valueRef.current.value )
        //         return;
        //
        //     let selected = getSelected(options,valueRef.current.value);
        //     if ( selected && selected.value )
        //         setSelected({ value: selected.value.toString(), desc: selected.name });
        // }
    },)

    useEffect(()=>{
        //if(defaultValue){
        if(options) setDefaultValues();
        //}

    },[options,defaultValue])

    useEffect(()=>{
        if ( valueRef.current )
            if ( multiple )
                Array.from(valueRef.current.children)
                    .map((opt, k) => opt.selected = selectedOpt.indexOf(k) !== -1);
            else if ( options[selectedOpt] )
                valueRef.current.value = options[selectedOpt].value;

        valueRef.current.dispatchEvent(new Event('blur'));
    }, [selectedOpt]);

    let useIcon = false;

    if(props.Required) useIcon = <RequiredText />

    if(valid){
        useIcon = (<img src={Check} />);
    }

    return(
        <Frag>
            {label && !useIcon && <Label htmlFor={name}>{label} {tooltip && <Help text={tooltip}/>}</Label>}
            {label && useIcon && <Label htmlFor={name}>{label} {useIcon} {tooltip && <Help text={tooltip}/>}</Label>}
            <SelectUi onClick={handlOpenSelect} ref={nodeSelect} className={[open && 'open', error && 'error', valid && 'valid']} {...props}>
                <Value style={{display:'inline-flex',alignItems:'center',overflow:'hidden'}}>
                    {
                        selectedOpt.length > 0
                            ? <SelectValueRender options={options} selectedOpt={selectedOpt} type={type} noOptions={props.noOptions}/>
                            : <span>{placeholder}</span>
                    }
                </Value>
                {open ? <Icon.ArrowUp size='15px' color={Theme.colors.info.border} /> : <Icon.ArrowDown size='15px' color={Theme.colors.info.border} />}
                {open &&
                <SelectOption
                    options={options}
                    setSelected={setSelected}
                    selectedOpt={selectedOpt}
                    multiple={multiple}
                    type={type}
                    onChange={onChange}
                    noOptions={props.noOptions}
                    visibleOptionsQty={visibleOptionsQty}
                    openTo={openTo}
                />
                }
            </SelectUi>
            {help && <Span>{help}</Span>}
            {error && <SpanError><img src={Ex} style={{marginRight:'6px'}} /> {error}</SpanError>}

            {multiple
                ?
                <SelectHidden
                    name={name}
                    multiple
                    ref={mergeRefs([valueRef, ref])}
                    tabIndex={-1}
                >
                    {options && options.map( ( opt, k ) =>
                        <option value={opt.value} key={k}>{opt.name}</option>
                    )}
                </SelectHidden>
                :
                <input
                    name={name}
                    type='hidden'
                    ref={mergeRefs([valueRef, ref])}
                />
            }

        </Frag>
    );
})

const HandleState = (index,setSelected,selectedOpt,multiple,onChange, options) => {

    const key = selectedOpt.includes(index);

    let ret;

    if(key && multiple){
        selectedOpt.splice(selectedOpt.indexOf(index),1)
        ret = [...selectedOpt];
    }else{
        if(multiple){
            let newSelected = Object.assign([],selectedOpt);
            newSelected.push(index);
            ret = newSelected;
        }else{
            ret = [index];
        }
    }

    if ( !multiple && ret.toString() === selectedOpt.toString() )
        return;

    const stateMap = options.filter( (opt, index) => ret.indexOf( index ) !== -1 ).map( opt => opt.value );

    if ( typeof onChange === 'function' )
        onChange( multiple ? stateMap : stateMap[0] );

    setSelected(ret);
}

const SelectOption = ({options,setSelected,selectedOpt,multiple,type,onChange,noOptions,visibleOptionsQty,openTo}) => {
    if(!options) return (<OptionsPeople><li>{noOptions ? noOptions : 'No data found'}</li></OptionsPeople>)
    return (
        <OptionsPeople visibleOptionsQty={visibleOptionsQty} openTo={openTo}>
            {options.map((item,key)=>(
                <Fragment key={key} >
                    {item.value !== undefined && item.value !== null ?
                        <li value={item.value} className='1010list-option' onClick={()=>{HandleState(key,setSelected,selectedOpt,multiple,onChange,options)}}>
                            {multiple && <CustomCheckBox className='1010list-option'>{selectedOpt.includes(key) && <Icon.Check size='17px' />}</CustomCheckBox>}
                            {type == 'color' ? <ColorOption color={item.color}  className='1010list-option' /> : null}
                            {type == 'people' ? <PhotoComponent profile={item.profile} /> : null}
                            <Value className='1010list-option'>{item.name}</Value>
                        </li>
                        :
                        <OptionGroup className='1010list-option'>{item.name}</OptionGroup>
                    }
                </Fragment>
            ))}
        </OptionsPeople>
    );
}

const PhotoComponent = ({profile,index}) => {
    let customStyle = {position:'relative',display:'inline-flex',zIndex:10};

    if(index > 0){
        customStyle.marginLeft = '-19px';
        customStyle.zIndex -= index;
    }
    return(
        <PhotoCover style={customStyle} className='1010list-option' >
            {profile && profile != undefined ? <img src={profile} className='1010list-option' /> : <Icon.Person className='1010list-option' size='20px' />}
        </PhotoCover>
    )
};

const SelectValueRender = ({options,selectedOpt,type,noOptions}) => {
    if(!options){
        return(
            <Fragment>
                <div style={{display:'flex'}}></div>
                <Value>
                    {noOptions ? noOptions : 'No Data' }
                </Value>
            </Fragment>
        );
    }
    let Pictures = [];
    let Names = [];

    if(selectedOpt.length > 0 && selectedOpt[0] != undefined){
        let seq = 0;
        selectedOpt.map((key)=>{
            if(seq < 3){
                if(type === 'color'){
                    Pictures.push(<ColorOption key={key} index={seq} color={options[key].color} />)
                }else if(type === 'people'){
                    Pictures.push(<PhotoComponent key={key} index={seq} profile={options[key].profile} />);
                }
                if(seq == 2){
                    let total = selectedOpt.length-2;
                    const plural = (total > 1 ? 's' : '');

                    if(Names[0].length > 8)
                    {
                        let new_desc = Names[0].split(' ');
                        if(new_desc[0].length >8){
                            Names.splice(1,1);
                            ++total;
                        }else{
                            Names[0] = new_desc[0];
                        }
                    }

                    Names.push(total+' other'+plural);
                }else{
                    let desc = options[key].name;
                    if(seq == 1){
                        // let new_desc = desc.split(' ');
                        // desc = new_desc[0];
                    }
                    Names.push(desc);


                }
            }
            ++seq;
        })
    }

    return(
        <Fragment>
            <div style={{display:'flex'}}>{Pictures}</div>
            <Value>
                {
                    (Names.length > 2) ? Names.join(', ').replace(/,(?=[^,]*$)/, ' and ') : Names.join(', ')
                }
            </Value>
        </Fragment>
    );

}


export {CustomDropdown};

export default Dropdown;

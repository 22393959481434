import React from "react";
import styled from 'styled-components';

const BtnGroup = styled.div`
    display: inline-flex;
    flex-wrap: nowrap;

    > * {
        &:first-child:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
`;

const ButtonGroup = ({children, ...props}) => {
    const generateClassName = btnProps => {
        let cn = btnProps.className || '';
        let track = btnProps.track;

        if ( props.active === track )
            cn += ' selected ';

        return cn;
    }


    return (
        <BtnGroup>
            {children.map( (btn, k) =>
                btn.type({
                    ...props,
                    ...btn.props,
                    key: k,
                    className: generateClassName(btn.props)
                })
            )}
        </BtnGroup>
    )
};

export default ButtonGroup;

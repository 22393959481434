import React, {useState, forwardRef} from 'react';

import Icon from '../Icons';

import Input from './index';


const  Password =  forwardRef(({...props},ref) => {
  const [visible, setVisible] = useState(false);

  const ImgStyle = {position:'absolute',top:'8px',right:'8px',height:'25px',cursor:'pointer'}


  let eyeImg = (<Icon.Eye style={ImgStyle} size='25px' color='#7C98B6' onClick={() => {setVisible(true)}} />);
  let type = 'password';
  if(visible){
    eyeImg = (<Icon.EyeCrossed  style={ImgStyle} size='25px' color='#7C98B6' onClick={() => {setVisible(false)}} />);
    type = 'text';
  }

  return (
    <div style={{position:'relative',display:'inline-block',verticalAlign:'middle'}} className='formAligner'>
      <Input {...props} type={type} iconAction={eyeImg} ref={ref} />
    </div>
    )
})

export default Password;

import React, {forwardRef} from 'react'
import styled from 'styled-components'

import Theme from '../Theme'
import Icon from '../Icons'
import Spacer from '../Spacer'
import Ex from '../assets/Ex.svg'
import Check from '../assets/Check.svg'

import {RequiredText} from '../Common/input'
import {Text} from '../Texts'

const HiddenCheckbox = styled.input.attrs({type:'checkbox'})`
    display: none;
`;

const HiddenRadioBox = styled.input.attrs({type:'radio'})`
    display: none;
`;


const CheckboxContainer = styled.label`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    cursor:pointer;

    &:focus {
        outline:none;
    }
 `;

const CustomCheckBox = styled.div`
    background: ${Theme.colors.neutrals.white};
    border-radius: ${Theme.defaultRadius};
    border:1px solid ${Theme.colors.neutrals.silver};
    height:${props => props.radio ? '17px' : '16px'};
    width:${props => props.radio ? '17px' : '16px'};
    border-radius:${props => props.radio ? '17px' : '2px'};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing:border-box;

    svg {
        display:none;
    }

    ${HiddenCheckbox}:focus + &, ${HiddenRadioBox}:focus + & {
        border-color:${Theme.colors.secondary};
        box-shadow:0 0 6px ${Theme.colors.action};
    }

    ${HiddenCheckbox}:disabled + &, ${HiddenRadioBox}:disabled + & {
        background:${Theme.colors.neutrals.silver};
        border-color:#707070;
        opacity:0.5;
        cursor:default;
    }

    ${HiddenCheckbox}:checked + &, ${HiddenRadioBox}:checked + & {
        svg {
            display:block;
        }
    }
`;

const Title = styled.h5`
    font-family:circe-rounded;
    font-weight:normal;
    font-size:16px;
    color: #707070;

    >.requiredField {
        color:${Theme.colors.error.text};
    }
`;

const Label = styled.span`
    display:inline-block;
    font-size:14px;
    color:#707070;
    margin-left:8px;
`;

const Span = styled.span`
    font-size:${Theme.font.extraSmall.size};
    font-weight:normal;
    font-family:Circe-Rounded;
    display:block;
    color:#516F90;
    margin-top:4px;
`;

const SpanError = styled(Span)`
    color:${Theme.colors.error.text};
    display:block;
`;

const NoOptions = styled(Text)`
    color: ${Theme.colors.neutrals.silver};
`;

const CheckboxBase = forwardRef(({name, checked=false,disabled,title,label,error,preventClass,...props},ref) => {
    return(
        <div style={{display:'inline-block'}} className={preventClass ? preventClass : ''}>
            {title && <Title>{title}</Title>}
            <CheckboxContainer className={preventClass ? preventClass : ''}>
                <HiddenCheckbox ref={ref}   disabled={disabled} name={name} id={`${name}.${props.value}`}  {...props} onChange={()=>{}} defaultChecked={checked} />
                <CustomCheckBox className={preventClass ? preventClass : ''}>
                    <Icon.Check size='16px' className={preventClass ? preventClass : ''} />
                </CustomCheckBox>
                <Label className={preventClass ? preventClass : ''}>{label}</Label>
            </CheckboxContainer>
            {error && <SpanError><img src={Ex} style={{marginRight:'6px'}} /> {error}</SpanError>}
        </div>
    );
});


const CheckBox = forwardRef(({name,title,options = [],values,error,...props},ref) => {

    // if(!options || !options[0] || options[0] == undefined) {
    //     return (<div>Provide an valid array</div>);
    // }

    return (
        <div style={{verticalAlign:'middle'}} className='formAligner'>
            {title && <Title>{title} {props.Required && <RequiredText />}</Title>}
            {
                options.map((item,index) => {
                    let checked = false;

                    if(props.defaultValues && props.defaultValues.includes(item.value)) checked = true;

                    return (
                        <Spacer key={index} right={3} style={{display: 'inline-block'}} >
                            <CheckboxBase
                                key={index}
                                name={name}
                                value={item.value}
                                label={item.label}
                                {...props}
                                ref={ref}
                                checked={checked}
                            />
                        </Spacer>
                    )
                })
            }
            {!options || !options.length && <NoOptions>No options available</NoOptions>}
            {error && <SpanError><img src={Ex} style={{marginRight:'6px'}} /> {error}</SpanError>}
        </div>
    );

});


const RadioBoxBase = forwardRef(({name,title,label,error,checked=false, ...props},ref) => {
    return(
        <div style={{display:'inline-block'}}>
            {title && <Title>{title} </Title>}
            <CheckboxContainer>
                <HiddenRadioBox ref={ref}  name={name} id={`${name}.${props.value}`} defaultChecked={checked} {...props} />
                <CustomCheckBox radio={true}>
                    <Icon.Check size='17px' />
                </CustomCheckBox>
                <Label>{label}</Label>
            </CheckboxContainer>
            {error && <SpanError><img src={Ex} style={{marginRight:'6px'}} /> {error}</SpanError>}
        </div>
    );
});

const RadioBox = forwardRef(({name,title,options = [],values,error,valid,checked,inline=true,...props},ref) => {

    // if(!options || !options[0] || options[0] == undefined) {
    //     return (<div>Provide an valid array</div>);
    // }


    return (
        <div  style={{verticalAlign:'middle'}} className='formAligner'>
            {title && <Title>{title} {valid && <img src={Check} />} {props.Required && <RequiredText />}</Title> }
            {!inline && title && <Spacer bottom={1} />}
            {
                options.map((item,index) => {
                    let checked = false;

                    if(props.default && props.default == item.value) checked = true;

                    return (
                        <Spacer key={index} right={3} style={{display: (inline ? 'inline-block' : 'block') }} >
                            <RadioBoxBase
                                key={index}
                                name={name}
                                label={item.label}
                                value={item.value}
                                checked={checked}
                                {...props}
                                ref={ref}
                            />
                        </Spacer>
                    )
                })
            }
            {!options || !options.length && <NoOptions>No options available</NoOptions>}
            {error && <SpanError><img src={Ex} style={{marginRight:'6px'}} /> {error}</SpanError>}
        </div>
    );

});

export {CheckboxBase};

export {CheckBox, RadioBox}

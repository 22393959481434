import styled from 'styled-components';
import Theme from '../Theme';

const Title = styled.h1`
  font-family:circe-rounded;
  font-weight:bold;
  font-size:36px;
  line-height:44px;
  color: ${Theme.colors.secondary};
`;

const SubTitle = styled.h3`
  font-family:circe-rounded;
  font-weight:bold;
  font-size:22px;
  line-height:28px;
  color: ${Theme.colors.info.text};
`;

const ThirdTitle = styled(SubTitle)`
   font-size:16px;
   line-height:20px;
`;

const Text = styled.p`
  font-size:14px;
  line-height:16px;
  color:${Theme.colors.neutrals.gray};
`;

export {Title, SubTitle, ThirdTitle, Text};



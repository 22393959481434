import React, {useState,forwardRef,useEffect} from 'react';
import styled from 'styled-components';
import {Label,SpanHelp,SpanError} from '../Common/input'
import Help from '../Common/tooltip'
import Icon from '../Icons';
import Ex from '../assets/Ex.svg'

import Theme from '../Theme';

import calculateContrast from '../Common/ColorHelper';

const Frag = styled.div`
    display:inline-block;
    vertical-align: top;
`;

const Field = styled.div`
  position:relative;
`;

const WraperBucket = styled.div`
  height:40px;
  width:40px;
  background:${props=>props.background ? props.background : Theme.colors.info.shadow};
  border-radius:40px;
  cursor:pointer;
  display:flex;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;

  &.hover {
    border:1px solid #0071BC;
    box-shadow: 0px 0px 6px #9DDDF4;
  }

  &:focus{
    box-shadow:0px 0px 6px ${Theme.colors.action};
    border:1px solid ${Theme.colors.secondary};
    outline:none;
  }

`;

const ColorBox = styled.ul`
  background:#fff;
  border-radius:2px;
  padding:5px;
  list-style:none;
  margin:0;
  display: grid;
  grid-template-columns: 24px 24px 24px 24px 24px 24px 24px 24px;
  position:absolute;
  left:48px;
  top:0;
  box-shadow: 0px 3px 6px ${Theme.colors.neutrals.gray}19;

  > li {
    width:24px;
    height:24px;
    cursor:pointer;
    position: relative;

    &.selected {
        z-index: 1;
        box-shadow: 0 0 0 2px ${Theme.colors.secondary};
        border-radius: ${Theme.defaultRadius};
    }

    &:hover {
        z-index: 2;
        box-shadow: 0 0 0 2px ${Theme.colors.neutrals.gray};
        border-radius: ${Theme.defaultRadius};
    }
  }
`;

const SelectHidden = styled.select`
    position: absolute;
    height: 1px;
    width: 1px;
    z-index: -1;
    opacity: 0;
`;

const InputColor = forwardRef(({name,tooltip,value,help,error, label,...props},ref) => {
  const [open, setOpen] = useState(false);

  const colors = [
    '#7C98B6','#00A4BD','#00BDA5','#F5C26B','#F2545B','#f03063','#BD37A4','#7B87D4',
    '#516F90','#0091AE','#00A38D','#DBAE60','#D94C53','#cc2957','#853E78','#5A65B0',
    '#CBD6E2','#7FD1DE','#7FDED2','#FAE0B5','#F8A9AD','#f59fb7','#E3AFD9','#B4BBE8',
    '#EAF0F6','#E5F5F8','#E5F8F6','#FEF8F0','#FDEDEE','#fce3eb','#F7E4F4','#F0F1FA',
  ];

  let defaultState = [];

  if(value){
    const isBlack = calculateContrast(value,'#333333');
    const textColor = (isBlack) ? '#333333' : '#ffffff';
    defaultState = [colors.filter(color=> color == value),textColor];
  }

  const [selectedColor, setSelectedColor] = useState(defaultState);



  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };

  },[])

  const handleClick = e => {

    if(e.target.classList.contains('colorEventPrevent'))
      return;

    setOpen(false);
  }

  const handleSelect = color => {
    const isBlack = calculateContrast(color,'#333333');
    const textColor = (isBlack) ? '#333333' : '#ffffff';
    setSelectedColor([color,textColor]);
    setOpen(false);
  }


  return(
    <Frag>
        {label && <Label htmlFor={name}>{label} {tooltip && <Help text={tooltip}/>}</Label>}
      <Field>
        <WraperBucket tabIndex={1} onClick={()=>setOpen(!open)} className={['colorEventPrevent', open && 'hover']} background={selectedColor[0] && selectedColor[0]}>
          <Icon.Bucket size='28px'  className='colorEventPrevent' color={selectedColor[1] ? selectedColor[1] : '#333'} />
        </WraperBucket>
        {open &&
          <ColorBox className='colorEventPrevent'>
            {colors.map((item,key)=>(
              <li
                  key={key}
                  className={'colorEventPrevent' + (item === selectedColor[0] ? ' selected' : '')}
                  style={{background: item}}
                  onClick={() => handleSelect(item)}
              />
            ))}
          </ColorBox>
        }
        {help && <SpanHelp>{help}</SpanHelp>}
        {error && <SpanError><img src={Ex} style={{marginRight:'6px'}} /> {error}</SpanError>}

        <SelectHidden name={name} multiple ref={ref} value={selectedColor} onChange={()=>{}}>
          {selectedColor.map((item,key)=>(
            <option value={item} key={key}></option>
          ))}
        </SelectHidden>
      </Field>
    </Frag>

  )
})

export default InputColor;

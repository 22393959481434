import React from 'react';
import styled from 'styled-components';

const Form = styled.form`
  display:flex;
  flex-wrap:wrap;
  flex:1;
  justifyContent:center;
  width:${props => props.width ? props.width : '100%'}
  
 
 >.formAligner {
  margin:0 10px 20px 10px;
 } 
 
`;

export default Form;

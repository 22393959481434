const defaultRadius='2px';

const colors = {
    primary:"#F7931E",
    secondary: "#0071BC",
    action: "#9DDDF4",
    neutrals: {black:"#000000",gray:"#333333",medium:"#707070",silver:"#CCCCCC",white:"#FFFFFF"},
    warnings: {border:"#F5C26B",text:"#DBAE60",shadow:"#FAE0B5",bg:"#FEF8F0"},
    success: {border:"#00BDA5",text:"#00A38D",shadow:"#7FDED2",bg:"#E5F8F6"},
    error: {border:"#F2545B",text:"#D94C53",shadow:"#F8A9AD",bg:"#FDEDEE"},
    info: {border:"#7C98B6",text:"#516F90",shadow:"#CBD6E2",bg:"#EAF0F6"},
    extra: {whiteSmoke:'#F5F5F5',gray93:'#FAFAFA'}
};

const font = {
    big: {size:'20px', lineHeight:'20px'},
    medium: {size: '16px', lineHeight:'16px'},
    small: {size: '14px', lineHeight:'16px'},
    extraSmall: {size: '12px', lineHeight:'12px'},
    tiny: {size: '8px', lineHeight:'12px'},
    main:'circe-rounded',
    body: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};


const form = {
    input: {
        radius:'2px',
        padding:'12px 8px'
    },
  button: {
    primary: {bg:colors.primary,hover:'#C46B04 '},
    secondary: {bg:colors.secondary,hover:'#00538A'}

  }
};



const theme = {colors,form, font, defaultRadius};

export default theme;

import React, {forwardRef} from 'react';
import styled from 'styled-components';
import Theme from '../Theme';

const defaultShadow = (color) => {
    return `0px 0px 6px ${color}`;
}

const Base = styled.button`
    font-family:circe-rounded;
    font-weight:bold;
    border-radius:${Theme.defaultRadius};
    cursor:pointer;
    display:inline-block;
    background:${Theme.colors.info.bg}
    color:${Theme.colors.secondary}
    height:40px;
    line-height:40px;
    padding:0 16px;
    font-size: ${Theme.font.medium.size};

    > * {
        border-color: ${Theme.colors.info.shadow};
    }

    svg path {
        fill: ${Theme.colors.secondary};
    }

    &:hover,
    &.hover {
        background:${Theme.colors.info.shadow};
    }

    &:focus,
    &.focus {
        background:${Theme.colors.info.bg};
        box-shadow:${defaultShadow(Theme.form.button.secondary.bg)};
    }

    &:active,
    &.active {
        background:${Theme.colors.info.shadow};
        box-shadow:${defaultShadow(Theme.form.button.secondary.bg)};
    }

    &.selected {
        border: 1px solid ${Theme.form.button.secondary.bg};
    }

    &[disabled] {
        opacity: .5;
        cursor: not-allowed;
    }
`;

const Primary = `
    background:${Theme.form.button.primary.bg};
    color:${Theme.colors.neutrals.white};

    > * {
        border-color: ${Theme.colors.neutrals.white};
    }

    svg path {
        fill: ${Theme.colors.neutrals.white};
    }

    &:hover,
    &.hover {
        background:${Theme.form.button.primary.hover}
    }

    &:focus,
    &.focus {
        background:${Theme.form.button.primary.bg};
        box-shadow:${defaultShadow(Theme.form.button.primary.bg)};
    }

    &:active,
    &.active {
        background:${Theme.form.button.primary.hover}
        box-shadow:${defaultShadow(Theme.form.button.primary.bg)};
    }

    &.selected {
        border: 1px solid ${Theme.colors.neutrals.white};
    }
`;

const Secondary = `
    background:${Theme.form.button.secondary.bg};
    color:${Theme.colors.neutrals.white};

    > * {
        border-color: ${Theme.colors.neutrals.white};
    }

    svg path {
        fill: ${Theme.colors.neutrals.white};
    }

    &:hover {
        background:${Theme.form.button.secondary.hover}
    }

    &:focus {
        background:${Theme.form.button.secondary.bg};
        box-shadow:${defaultShadow(Theme.form.button.secondary.bg)};
    }

    &:active,
    &.active {
        background:${Theme.form.button.secondary.hover}
        box-shadow:${defaultShadow(Theme.form.button.secondary.bg)};
    }

    &.selected {
        border: 1px solid ${Theme.colors.neutrals.white};
    }
`;

const Cancel = `
    background:transparent;
    color:${Theme.form.button.secondary.bg};

    > * {
        border-color: ${Theme.colors.info.shadow};
    }

    svg path {
        fill: ${Theme.colors.secondary};
    }

    &:hover {
        background:transparent;
        text-decoration:underline;
    }

    &:focus {
        background:${Theme.colors.neutrals.white};
        box-shadow:${defaultShadow(Theme.form.button.secondary.bg)};
    }

    &:active,
    &.active {
        background:${Theme.colors.neutrals.white};
        box-shadow:${defaultShadow(Theme.form.button.secondary.bg)};
        text-decoration:underline;
    }

    &.selected {
        border: 1px solid ${Theme.form.button.secondary.bg};
    }
`;

const Delete = `
    background:${Theme.colors.neutrals.white};
    color:${Theme.colors.error.text};
    border:1px solid ${Theme.colors.error.bg};

    > * {
        border-color: ${Theme.colors.error.shadow};
    }

    svg path {
        fill: ${Theme.colors.error.text};
    }

    &:hover {
        border-color:${Theme.colors.error.border};
        background:${Theme.colors.error.bg};
    }

    &:focus {
        background:${Theme.colors.neutrals.white};
        border-color:${Theme.colors.error.border};
        box-shadow:${defaultShadow(Theme.colors.error.shadow)};
    }

    &:active,
    &.active {
        background:${Theme.colors.error.bg};
        border-color:${Theme.colors.error.border};
        box-shadow:${defaultShadow(Theme.colors.error.shadow)};
    }

    &.selected {
        border: 1px solid ${Theme.colors.error.border};
    }
`;

const Small = `
    height:28px;
    line-height:28px;
    font-size:${Theme.font.small.size};
    padding:0 12px;
`;

const Big = `
    height:60px;
    line-height:60px;
    font-size:${Theme.font.big.size};
    padding:0 20px;
`;

const Exportedd = styled(Base)`
    ${props => props.typeStyle === 'primary' && Primary}
    ${props => props.typeStyle === 'secondary' && Secondary}
    ${props => props.typeStyle === 'cancel' && Cancel}
    ${props => props.typeStyle === 'delete' && Delete}
    ${props => props.size === 'small' && Small}
    ${props => props.size === 'big' && Big}
`;

const Exported = ({size,type,children,Type,...props}) => {
    return (
        <Exportedd
            type={
                Type
                    ? Type
                    : type === 'cancel'
                        ? 'button'
                        : undefined
            }
            size={size}
            typeStyle={type}
            children={children}
            {...props}
        />
    );
};

export default Exported;
export {Base, Primary, Secondary, Cancel, Delete, Big, Small};

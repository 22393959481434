import React, {useRef, Children, useEffect,useState} from 'react'
import styled, {keyframes} from 'styled-components'

const listAnimation = keyframes`
    0% {
        opacity:0;
        visibility: hidden;
        transform: scale(0.6) translateY(-8px);
    }

    100% {
        visibility: visible;
        opacity:1;
    }
`

const POSITION_LIST = '100%'
const POSITION_LIST_DISTANCE = '10%'

const generateAnimationDelay = (n, direction) => {
  let style = ''
  if (['left','top'].includes(direction)) {
    let delayNumber = 1
    for (let i = n; i >= 1; --i) {
      style += `
            > :nth-child(${i}) {
                animation-delay: ${delayNumber * 90}ms;
            }
        `
        delayNumber++
    }
  } else {
    for (let i = 1; i <= n; ++i) {
      style += `
            > :nth-child(${i}) {
                animation-delay: ${i * 90}ms;
            }
        `
    }
  }

  return style
}

const ListContent = styled.div`
    position:absolute;
    height:${props => ['left','right'].includes(props.direction) ? '100%' : 'fit-content'};
    padding:2px;
    white-space: nowrap;
    align-items:center;
    gap: ${props => props.spaceBetween}
    display:flex;
    visibility: hidden;

    ${props => generateAnimationDelay(props.childrenQuantity,props.direction)}

    ${props => (props.direction === 'right' || props.direction === 'auto') && `
        flex-direction:row;
        top:0;
        bottom:0;
        left:${POSITION_LIST};
        padding-left:${POSITION_LIST_DISTANCE};
    `}

    ${props => props.direction === 'left' && `
        flex-direction:row;
        top:0;
        bottom:0;
        right:${POSITION_LIST};
        padding-right:${POSITION_LIST_DISTANCE};
    `}

    ${props => props.direction === 'top' && `
        flex-direction:column;
        justify-content:end;
        bottom:${POSITION_LIST};
        right:0;
        left:0;
        padding-top:${POSITION_LIST_DISTANCE};
    `}

    ${props => props.direction === 'bottom' && `
        flex-direction:column;
        justify-content:start;
        top:${POSITION_LIST};
        bottom:0;
        right:0;
        left:0;
        padding-top:${POSITION_LIST_DISTANCE};
    `}
`

const PreviewContent = styled.div`
    cursor:pointer;
    background:white;
    border-radius:100%;
    padding:8px;
    display:flex;
    justify-content:center;
    border:1px solid rgb(204, 204, 204);
`

const Container = styled.div`
    position:relative;

    &:hover > ${ListContent} {
        visibility: visible;
    }

    &:hover > ${ListContent} > * {
        animation-name: ${listAnimation};
        animation-duration: 200ms;
        animation-fill-mode: backwards;
        animation-timing-function: ease-in-out;
    }
`

const ButtonIcon = ({children, event = 'Hover'}) => {
  if (children.length > 2) {
    throw 'This component only accepts a Button.Preview and Button.List component'
  }

  const [Preview, List] = children
  if (Preview.type.name !== 'ButtonPreview') {
    throw `The first component should be ButtonIcon.Preview, but got ${Preview.type.name}`
  }

  if (List.type.name !== 'ButtonList') {
    throw `The first component should be ButtonIcon.List, but got ${Preview.type.name}`
  }

  return (
    <Container>
      {Preview}
      {List}
    </Container>
  )
}

const ButtonPreview = ({children}) => {
  const previewRef = useRef()
  return <PreviewContent ref={previewRef}>{children}</PreviewContent>
}
ButtonIcon.Preview = ButtonPreview

const DIRECTIONS = ['bottom', 'left', 'right', 'top', 'auto']
const ButtonList = ({children, direction, spaceBetween}) => {
   const [dir, setDir] = useState(direction);

  if (!DIRECTIONS.includes(dir)) {
    throw `Invalid direction argument on component ButtonIcon.List, expect ${DIRECTIONS.join(', ')}`
  }
  if(['left','top'].includes(dir)) {
      children = Children.toArray(children).reverse()
  }

  const contentRef = useRef();
  useEffect(() => {
      if(!contentRef?.current) return

      if(dir !== 'auto') return

      const bounds = contentRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;


      if(bounds.right > windowWidth ){
          setDir('left')
          return
      }else{
          return
      }

      if(bounds.left < 0 ){
          setDir('bottom')
          return
      }

      if(bounds.bottom > windowHeight){
          setDir('top')
          return
      }
  },[contentRef])

  return (
    <ListContent
      ref={contentRef}
      direction={dir}
      spaceBetween={spaceBetween}
      childrenQuantity={children.length}
    >
      {children}
    </ListContent>
  )
}
ButtonList.defaultProps = {
  direction: 'auto',
  spaceBetween: '8px'
}

ButtonIcon.List = ButtonList

export default ButtonIcon

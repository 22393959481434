import React, {useState} from 'react';
import styled from 'styled-components';
import Theme from '../Theme';
import Icon from '../Icons';

const Div = styled.div`
    padding: 7px;
    border-radius: ${Theme.defaultRadius};
    border-width:1px;
    border-style:solid;
    display:flex;
    align-items:center;
    position:relative;

    ${props => props.useClose && `
    padding-right: 32px;
    `}

    svg {
        margin-right: 8px;
        display: block;
    }
`;

const Title = styled.h5`
    font-family: ${Theme.font.main};
    font-weight: normal;
    font-size: ${Theme.font.medium.size};
    line-height: ${Theme.font.big.lineHeight};
`;

const Content = styled.p`
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.small.lineHeight};
`;

const SmallContent = styled(Content)`
    font-size: ${Theme.font.small.size};
    line-height: ${Theme.font.small.lineHeight};
`;

const Close = styled.button.attrs(props => ({
    type: 'button'
}))`
    border: none;
    background: none;
    padding: 0;
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;

    svg {
        margin: 0;
    }
`;

const solveContent = (size,title,content) => {
    const isSmall = size === 'small';

    if ( isSmall )
        return (
            <Title>
                <SmallContent>{title}</SmallContent>
            </Title>
        );

    return (
        <div>
            <Title>{title}</Title>
            <Content>{content}</Content>
        </div>
    );
}

const Alert = (props,type,Symbol) => {
    const {title,content, useIcon, useClose, size=40} = props;

    const [isOpen, setOpen] = useState(true);
    if(!isOpen){
        return null;
    }

    const typeStyle = {
        background: Theme.colors[type].bg,
        borderColor: Theme.colors[type].border,
        color: Theme.colors[type].text
    };

    let addPadding = ( type === 'error' || type === 'success' ) && size !== 'small';

    return (
        <Div {...props} style={typeStyle}>
            {useIcon && (
                <div style={{
                    padding: addPadding ? 6 : 0
                }}>
                    {React.createElement(
                        Symbol,
                        {
                            size: size === 'small' ? '16px' : addPadding ? size - 12 + 'px' : size + 'px',
                            color: typeStyle.color
                        }
                    )}
                </div>
            )}
            {solveContent(size,title,content,useClose)}
            {useClose && <Close onClick={()=> setOpen( false ) }><Icon.Times size='16px' color={typeStyle.color}/></Close>}
        </Div>
    );
}

const AlertError = (props) => {
    const typeAlert = 'error';
    const Symbol = Icon.TimesNarrow;
    return Alert(props,typeAlert,Symbol);
}

const AlertInfo = (props) => {
    const typeAlert = 'info';
    const Symbol = Icon.Info;
    return Alert(props,typeAlert,Symbol);
}

const AlertWarning = (props) => {
    const typeAlert = 'warnings';
    const Symbol = Icon.Warning;
    return Alert(props,typeAlert,Symbol);
}

const AlertSuccess = (props) => {
    const typeAlert = 'success';
    const Symbol = Icon.CheckNarrow;
    return Alert(props,typeAlert,Symbol);
}

export default {
    Success: AlertSuccess,
    Info: AlertInfo,
    Warning: AlertWarning,
    Error: AlertError
}
